import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main } from '../content/depoty'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Tiles from '../components/Tiles'
import { sHtml, sSection, s } from '../style'

const Spedycja = ({ data }) => {
  const { lang } = useLangContext()
  const { image, children, longDescription } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/spedycja.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 100
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      children: allContentfulDepot(sort: { fields: name }) {
        nodes {
          name
          slug
          lang {
            slug
          }
          noCreatePage
          image {
            gatsbyImageData(width: 480, quality: 100, formats: [JPG])
          }
        }
      }
      longDescription: contentfulDodatkowyTekst(slug: {eq: "depoty"}) {
        text: childContentfulDodatkowyTekstBodyTextNode {
          childrenMarkdownRemark {
            html
          }
        }
      }
    }
  `)

  
  const { text } = longDescription ? longDescription : ''
  const longDesc = text ? text.childrenMarkdownRemark[0].html : ''
  console.log(longDesc)
  
  const links = children.nodes
    .filter((node) => node.lang.slug === lang)
    .filter((node) => !node.noCreatePage)
    .map((node) => ({
      slug: node.slug,
      name: node.name,
      image: node.image,
    }))

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/spedycja.jpg'}
        url={seo.url}
      />
      <Intro data={intro} image={image.childImageSharp} />
      <Main data={main} h={1} />
      <Tiles data={links} />
      {longDesc && (
        <section
          css={[
            sSection,
            sTest,
            { [s.md]: { padding: '1rem 3rem 4rem' } }
        ]}>
          <article css={sHtml} dangerouslySetInnerHTML={{ __html: longDesc }} />
        </section>
      )}
    </Layout>
  )
}

const sTest = {
  marginTop: '0px !important'
}

export default Spedycja
